<template>
    <teleport to='#modal'>
        <div v-if="charge_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-4 py-5 rounded-md text-center">
                <form class="">
                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Charge Percentage (%)</p>
                    </div>
                    <div class="gap-4">
                        <div class="form-group mb-6">
                            <input type="number" v-model="percent" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="percent">
                        </div>                                                        
                    </div>                      
                </form>
                <button @click="leaving_modal = true, turnOffModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="saveCharge()" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Save</button>
            </div>
        </div>
    </teleport>
    <!-- End of new charge -->

    <div class="p-4">
        <top-search></top-search>

        <div class="w-full flex justify-between my-4 p-4">
            <label for="charges" class="font-semibold capitalize">charges</label>
            <div class="flex justify-between">
                <button class="px-5 py-2 rounded-lg hover:outline">
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
                <button @click="leaving_modal = false, charge_modal = true" class="ml-4 bg-brown-default text-gray-50 px-5 py-2 rounded-lg">Add Charge</button>
            </div>
        </div>

        <div class="w-full p-5">
            <div class="bg-white flex justify-between p-4">
                <div>
                    <input type="text" placeholder="Search" v-model="search" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
                
                <div>
                    <input type="date" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
            </div>
        </div>

        <div v-if="!charge_loader" class="w-full mb-12 px-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">                
                <div class="block w-full overflow-x-auto">
                    <table class="table-auto items-center w-full bg-transparent border-collapse">
                        <thead class="">
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Percentage Charge (%)</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date Created</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                            </tr>
                        </thead>

                        <tbody v-if="charges" class="overflow-y-auto transition duration-700 ease-in-out">
                            <tr class="bg-white border-b-2" v-for="(charge, index) in filteredCharges" :key="index">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ charge.percent }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(charge.created_at).format('DD-MMM-YYYY') }}</td>
                                <td v-if="charge.status" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-md whitespace-nowrap p-4 text-left"> <button class="bg-green-600 text-white text-sm py-1 px-2 rounded-md uppercase"> active </button></td>                                    
                                <td v-else class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-md whitespace-nowrap p-4 text-left"> <button class="bg-red-600 text-white text-sm py-1 px-2 rounded-md uppercase"> inactive </button></td>                                    
                            </tr>
                        </tbody>
                        <tbody v-else class="overflow-y-auto transition duration-700 ease-in-out">
                            <tr class="bg-white border-b-2">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">No data available</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
export default {
    name: 'charge',
    components: {
        TopSearch,
        SquareGrid,
    },
    data() {
        return {
            percent: '',
            moment: moment,
            charge_modal: false,
            search: "",
            leaving_modal: false,
        }
    },
    computed: {
        ...mapGetters({
            charges: 'chargeModule/getCharges',
            charge_loader: 'chargeModule/getChargeLoader', 
            charge_created: 'chargeModule/getChargeCreated',           
        }),

        filteredCharges () {
            return this.charges.filter(charge => {
                return charge.percent.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
                    charge.created_at.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            })
        },

    },
    methods: {
        saveCharge() {
            this.$store.dispatch('chargeModule/postChargesAction', {percent: this.percent})
            .then(() => { 
                if (this.charge_created) {
                    Toast.fire({
                        icon: 'success',
                        title: this.charge_created.message,
                    })
                    this.turnOffModal()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Unabled to process..."
                    })
                    
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Unabled to process..."
                })
                
            })
        },

        turnOffModal() {
            setTimeout(() => {
                this.charge_modal = false;
            }, 500)
            
        },
    }
}
</script>

<style scoped>
    .scale-in-center {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    .scale-out-center {
        -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }

    @-webkit-keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }

    @keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }


</style>
